import translation from '@/mockedData/translation.json'

class Translation77 {
  constructor(json) {
    this.elems = null
    this.init(json)
  }

  init(json) {
    try {
      // this.elems = JSON.parse(json) // @TODO: use proper translation
      this.elems = json
    } catch (err) {
      console.error(err.message)
    }
  }

  L(url) {
    if (this.elems != null && this.elems.Urls[url] !== undefined) {
      return decodeURIComponent(this.elems.Urls[url])
    } else {
      return decodeURIComponent(url)
    }
  }

  T(code, en = '') {
    if (this.elems != null && this.elems.Translations[code] !== undefined) {
      return this.elems.Translations[code]
    } else if (this.elems != null && this.elems.CommonTranslations[code] !== undefined) {
      return this.elems.CommonTranslations[code]
    } else {
      return en
    }
  }

  TS(code, en = '') {
    return this.T(code, en).replace(/<(?:.|\n)*?>/gm, '')
  }
}

export default new Translation77(translation)
