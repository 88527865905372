import { useStorage } from '@vueuse/core'

export const SHOPPING_BAG_KEY = getStorageKey('shoppingBagItems')

const shoppingBagItems = useStorage(SHOPPING_BAG_KEY, [], undefined, {
  mergeDefaults: (storageValue, defaults) =>
    isStorageKeExpired(SHOPPING_BAG_KEY) ? defaults : storageValue ?? defaults,
}) as Ref<string[]>

// Get valid storage value or empty array, execute on client side only
const getValidStorageValue = () => (isStorageKeExpired(SHOPPING_BAG_KEY) ? [] : shoppingBagItems.value)

// return read-only shoppingBagItems, to avoid shoppingBagItems update from outside
export const getShoppingBagItems = () => readonly(shoppingBagItems)

// Listen to storage events to sync changes across tabs
export const initShoppingBagLocalStorageListener = () => {
  window.addEventListener('storage', (event: StorageEvent) => {
    if (event.key === SHOPPING_BAG_KEY) {
      shoppingBagItems.value = event.newValue ? JSON.parse(event.newValue) : []
    }
  })
}

export function increaseShoppingBagItems(bagUniqueCode: string) {
  const validStorageValue = getValidStorageValue()

  if (!validStorageValue.includes(bagUniqueCode)) {
    shoppingBagItems.value = [...validStorageValue, bagUniqueCode]
    setExpiryForKey(SHOPPING_BAG_KEY)
  }
}

export function decreaseShoppingBagItems(bagUniqueCode: string) {
  const validStorageValue = getValidStorageValue()
  const index = validStorageValue.indexOf(bagUniqueCode)

  if (index > -1) {
    shoppingBagItems.value.splice(index, 1)
    setExpiryForKey(SHOPPING_BAG_KEY)
  }
}
