import { useRouteStore } from '~/store/routesStore.ts'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const locale = getLanguageCodeFromRoute(to)
  const routeStore = useRouteStore()

  if (locale !== routeStore.locale) {
    const { data, error } = await useFetch(`/api/shop/routes?locale=${locale}`)
    if (error.value) {
      console.error('Failed to load shopRoutes:', error)
    }

    routeStore.setShopRoutes(data.value)
    routeStore.setLocale(locale)
  }
})
