export const GEMSTONE_GUIDE_SECTION_TYPES = {
  SUBTITLE: 'subtitle',
  TEXT: 'text',
  HTML: 'html',
  IMAGE: 'image',
}

export enum GEM_ORIGINS {
  BASALTIC = 442,
  MADAGASCAR = 443,
  MOZAMBIQUE = 444,
  MYANMAR = 445,
  SRI_LANKA = 446,
  TANZANIA = 447,
  THAILAND = 448,
  ZAMBIA = 473,
  EAST_AFRICA = 474,
  COLOMBIA = 475,
  BRAZIL = 476,
  ETHIOPIA = 477,
  ZIMBABWE = 478,
  VIETNAM = 480,
  GREENLAND = 493,
  CEYLON = 1008,
  AFRICA = 1010,
  KENYA = 1011,
  NIGERIA = 1012,
}

export enum CERTIFICATES {
  EGL = 49,
  GIA = 50,
  IGI = 51,
  HRD = 52,
  ICL = 411,
  SSEF = 462,
  GUBLIN = 463,
  AIGS = 466,
  GRS = 467,
  GCAL = 469,
  DF = 470,
  GR = 492,
  LGC = 508,
  TGL = 1001,
  IGL = 1302,

  EGL_USA = 54,
}
