import _77T from './77translation.js'
import { toSlug } from './formatting.js'

const debug = process.env.NODE_ENV !== 'production'

// TODO: delete this file after we move resolve translatURL, so it will work with i18n
export const translate = (value: string, alt?: string): string => {
  try {
    // Translate links inside [[ ]],
    // ex: v-html="$util.translate( 'finance-terms', '<a href=\'[[/NewApp/Default.aspx?app=terms]]\'>Read More</a>')"

    const regex = /\[\[(.+?)\]\]/gm
    let link, translatedText
    translatedText = _77T.T(value, alt || value)

    while ((link = regex.exec(translatedText)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (link.index === regex.lastIndex) {
        regex.lastIndex++
      }

      translatedText = translatedText.replace('[[' + link[1] + ']]', _77T.L(link[1]))
    }

    return translatedText
  } catch (e) {
    return alt || value
  }
}

export function translateURL(value: string): string {
  return _77T.L(value)
}

export function translateHeader(type: string, value: string, alt: string): string {
  // @ts-ignore
  const th: string = window[`_${type}`]

  if (th) {
    return th
  } else {
    return translate(value, alt)
  }
}

export function translateToSlug(value: string): string {
  return toSlug(translate(value))
}

export function translateAndReplace(shortnameAndText: string, listOfReplacements: string[]): string {
  let newText =
    shortnameAndText.length === 1 ? translate(shortnameAndText[0]) : translate(shortnameAndText[0], shortnameAndText[1])

  for (const replacement of listOfReplacements) {
    newText = newText.replace(replacement[0], replacement[1])
  }

  return newText
}
