import { useLoaderStore } from '~/store/loaderStore'

export const fetchWithLoader = async (url, options = {}) => {
  const { start, end } = useLoaderStore()

  try {
    start()
    return await $fetch(url, options)
  } catch (error) {
    throw error
  } finally {
    end()
  }
}
