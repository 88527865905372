const routeName = ref('')

export const useShopRouteName = () => {
  const setRouteName = (name) => {
    // console.log('useShopRouteName routeName', name)
    routeName.value = name
  }

  return {
    setRouteName,
    routeName,
  }
}
