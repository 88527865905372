type Payload = {
  id: string | number
  languageCode: string
}
// TODO: update after the actual API endpoint is available
const TRANSLATION_API = 'https://seventysevensandbox.blob.core.windows.net/translations-dev'

export async function fetchTranslation(payload: Payload): Promise<Record<string, string>> {
  try {
    const translationRoute = `${TRANSLATION_API}/${payload.languageCode}/${payload.id}.js`

    const { data, error } = await useAsyncData(translationRoute, async () => {
      const response = await $fetch(translationRoute)
      const text = await response.text()

      return JSON.parse(text)
    })

    if (error.value) {
      console.error('Failed to fetch translation', translationRoute, error.value)
      return {}
    }

    return data.value
  } catch (error) {
    console.error(error)
    throw error // Rethrow to handle in the calling function
  }
}
