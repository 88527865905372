export enum SHAPES {
  ROUND = 1,
  PRINCESS = 2,
  EMERALD = 3,
  ASSCHER = 4,
  PEAR = 5,
  RADIANT = 6,
  OVAL = 7,
  CUSHION = 8,
  HEART = 9,
  MARQUISE = 10,
  OLD_CUTS = 30,
  KITES_SHIELDS = 31,
  TRIANGULARS = 32,
  OCTAGON = 430,
}

export enum GEM_SHAPES {
  RECTANGLE = 1,
  OTHER = 2,
  SQUARE = 3,
  ROUND = 4,
  OVAL = 5,
  PEAR = 6,
  CUSHION = 7,
  HEART = 8,
  MARQUISE = 9,
}
