import settingsExpress from '@/mockedData/settingsExpress.json'
import naturalDiamondsFilters from '@/mockedData/naturalDiamondsFilters.json'
import gemFilters from '@/mockedData/gemFilters.json'
import attributesByStoneType from '@/mockedData/attributesByStoneType.json'
import attributesByCountry from '@/mockedData/attributesByCountry.json'

// TODO: use API to get settings
export const getSettingsExpress = () => {
  return settingsExpress // looks like express settings are contain similar parameters as shop settings
}
// TODO: use API to get settings
export const getNaturalDiamondsFilters = () => {
  return naturalDiamondsFilters
}

// TODO: use API to get settings
export const getGemFilters = () => {
  return gemFilters
}

export const getAttributesByStoneTypeFromSettings = () => {
  return attributesByStoneType
}

export const getAttributesByCountry = () => {
  return attributesByCountry
}
