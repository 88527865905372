export const useLoaderStore = defineStore('loaderStore', () => {
  const loadingCount = ref(0)
  const loading = computed(() => loadingCount.value > 0)
  const start = () => {
    loadingCount.value++
  }
  const end = () => {
    if (loadingCount.value > 0) {
      loadingCount.value--
    }
  }
  return { loading, start, end, loadingCount }
})
