export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:mounted', () => {
    if (window.ometria) {
      const mockedList = [
        {
          pid: '859_2_1',
          q: 1,
          tot: 1422.622,
          p: {},
        },
        {
          pid: '739_2_1',
          q: 1,
          tot: 1865.15332,
          p: {},
        },
      ]
      window.ometria.init('product', mockedList)
    } else {
      console.error('Ometria script failed to load.')
    }
  })
})
