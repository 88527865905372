import validate from "/src/node_modules/.pnpm/nuxt@3.9.1_@parcel+watcher@2.3.0_@types+node@18.19.7_encoding@0.1.13_eslint@8.56.0_optionator_t7pwgj677tsyg74zmxcwjpvgpa/node_modules/nuxt/dist/pages/runtime/validate.js";
import i18n_45global from "/src/middleware/i18n.global.js";
import shop_45init_45routes_45global from "/src/middleware/shopInitRoutes.global.js";
import manifest_45route_45rule from "/src/node_modules/.pnpm/nuxt@3.9.1_@parcel+watcher@2.3.0_@types+node@18.19.7_encoding@0.1.13_eslint@8.56.0_optionator_t7pwgj677tsyg74zmxcwjpvgpa/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  i18n_45global,
  shop_45init_45routes_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "asp-to-query": () => import("/src/middleware/aspToQuery.js"),
  "reset-filters": () => import("/src/middleware/resetFilters.js"),
  shop: () => import("/src/middleware/shop.js"),
  "shop-diamond": () => import("/src/middleware/shopDiamond.js"),
  "shop-diamond-step": () => import("/src/middleware/shopDiamondStep.js"),
  "shop-diamond-step-details": () => import("/src/middleware/shopDiamondStepDetails.js"),
  "shop-item": () => import("/src/middleware/shopItem.js"),
  "shop-routes": () => import("/src/middleware/shopRoutes.js")
}