import { LOCAL_STORAGE_PREFIX } from '@/constants'

const DEFAULT_EXPIRATION = 3 // 1 hour
export function removeExpiredStorage(storageName: string) {
  localStorage.removeItem(storageName)
  localStorage.removeItem(getStorageExpirationKey(storageName))
}

export function setExpiryForKey(storageName: string, expiryInSeconds = DEFAULT_EXPIRATION) {
  const expiryInMilliseconds = expiryInSeconds * 1000
  const now = new Date()
  const expiry = now.getTime() + expiryInMilliseconds
  const expiryKey = getStorageExpirationKey(storageName)

  localStorage.setItem(expiryKey, JSON.stringify(expiry))
}

export function isStorageKeExpired(storageName: string): boolean {
  const expiryKey = getStorageExpirationKey(storageName)
  const itemStr = localStorage.getItem(expiryKey)
  if (!itemStr) {
    return false
  }
  const item = JSON.parse(itemStr)
  const now = new Date()

  return now.getTime() > item
}

export function getStorageKey(key: string) {
  return LOCAL_STORAGE_PREFIX + key
}

export function getStorageExpirationKey(key: string) {
  return key + '_expiration'
}

export function getValidStorageItem(storageName: string, defaultValue: any = []) {
  const item = localStorage.getItem(storageName)

  return isStorageKeExpired(storageName) || !item ? defaultValue : JSON.parse(item)
}
