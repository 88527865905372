import categoriesData from '@/data/categories.json'
import { findBy, join } from './general.js'

export function getCategories() {
  return categoriesData.map((c) => ({ ...c, route: c.label }))
}

const categories = getCategories()

export function getCategoryById(id) {
  return findBy(getCategories(), 'id', id)
}

export function getCategoryByValue(value) {
  return findBy(categories, 'value', value)
}

export function getCategoryIdByValue(value) {
  return findBy(categories, 'value', value).id
}

export function getCategoryByLabel(label) {
  return findBy(categories, 'label', label)
}

export function getCategory(value) {
  if (isNaN(value)) return getCategoryByLabel(value) || findBy(categories, 'route', value)
  else return getCategoryById(value)
}

export function joinCategories(separator, field = 'label') {
  return join(categories, field, separator, 'route')
}
