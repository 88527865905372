import ringSizes from '@/data/ringSizes.json'

export function useUtils() {
  const route = useRoute()
  const { $i18n } = useNuxtApp()
  const ringSizesCountries = ringSizes.countries.map((c) => ({
    ...c,
    name: $i18n.t(c.shortname, c.name),
  }))

  // TODO: rename to getRingSizesCountries
  const getRingSizeCountriesData = () => {
    return ringSizesCountries
  }

  const getLanguages = async (LanguageId, CountryId, CurrencyId) => {
    try {
      return await $fetch('/api/v1/website/languages-for-header', {
        method: 'POST',
        body: {
          LanguageId,
          CountryId,
          CurrencyId,
          Uri: 'https://www.77diamonds.com/',
        },
      })
    } catch(e) {
      throw e
    }
  }
  const getCurrencies = async () => {
    try {
      return await $fetch('/api/v1/accounting/currencies-for-header')
    } catch(e) {
      throw e
    }
  }
  const getCountries = async () => {
    try {
      return await $fetch('/api/v1/logistics/countries')
    } catch(e) {
      throw e
    }
  }
  const getPhoneNumbers = async () => {
    try {
      return await $fetch('/api/v1/website/phone-numbers')
    } catch(e) {
      throw e
    }
  }

  return {
    getRingSizeCountriesData,
    getCurrencies,
    getCountries,
    getLanguages,
    getPhoneNumbers,
  }
}
