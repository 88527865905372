import { setCommonTranslations } from '~/helpers/i18n'

// Optimize it language loads, maybe it better to put into app.vue
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:created', async () => {
    const initialLocale = nuxtApp.$i18n.locale

    await setCommonTranslations(nuxtApp, initialLocale.value)
  })

  nuxtApp.hook('i18n:beforeLocaleSwitch', async ({ oldLocale, newLocale, context }) => {
    if (oldLocale === newLocale) return
    await setCommonTranslations(nuxtApp, newLocale)
  })
})
