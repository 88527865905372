import { gemShapes } from '@/constants/utilConstants.ts'
import gemFilters from '@/mockedData/gemFilters.json'

export function getGemShapes() {
  return getGemFilters('Shapes').map((shape) => ({
    Name: shape.Name,
    Id: shape.Id,
    img: gemShapes[shape.Id].img,
    group: shape.Attributes.map((a) => a.Id),
  }))
}

export function getGemFilters(type) {
  return gemFilters[type].GroupedAttributes.sort((a, b) => a.Position - b.Position) || []
}

export function getGemAttributeName(type, id) {
  return (getGemFilters(type).find((a) => a.Id == id) || {}).Name
}

export function getGemGroup(type, ids) {
  if (!Array.isArray(ids)) ids = [ids]
  try {
    return getGemFilters(type)
      .filter((s) => ids.includes(s.Id) || ids.includes(s.Id.toString()))
      .map((s) => s.Attributes.map((a) => a.Id))
      .flat()
  } catch (e) {}
}
