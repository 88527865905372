import { metals } from '@/constants/utilConstants.ts'
import { findBy, join } from './general.js'

export function getMetalById(id) {
  return findBy(metals, 'id', id) || {}
}

export function getMetalByValue(value) {
  return findBy(metals, 'value', value) || findBy(metals, 'route', value)
}

export function getMetalByLabel(label) {
  return findBy(metals, 'label', label)
}

export function getMetalIdByValue(value) {
  if (isNaN(value)) return (getMetalByValue(value) || {}).id
  else return value
}

export function getMetalRoute(value) {
  if (isNaN(value)) return (getMetalByValue(value) || { route: value }).route
  else return (getMetalById(value) || {}).route
}

export function joinMetals(separator, field = 'value') {
  return join(metals, field, separator, 'route')
}
