import shopRoutingItems from '@/mockedData/shopRoutingItems.json'
import { findBy, join, removeDuplicatedComplex } from './general.js'
// Import the utility functions from your project

let items = []
const getItemsSingleton = () => {
  //   items = removeDuplicatedComplex(Window._77Settings.Shop.Routing.Items, ['Id', 'SubCategoryId', 'FriendlyName']).map(
  if (items.length > 0) {
    return items
  }

  items = removeDuplicatedComplex(shopRoutingItems, ['Id', 'SubCategoryId', 'FriendlyName']).map((item) => ({
    id: item.Id,
    //category: item.,
    subcategory: item.SubCategoryId,
    label: item.FriendlyName,
  }))

  return items
}

export function getItems() {
  return getItemsSingleton()
}

export function getItemById(id) {
  return findBy(getItems(), 'id', id)
}

export function getItemByValue(value) {
  return findBy(getItems(), 'value', value)
}

export function getItemByLabel(label) {
  return findBy(getItems(), 'label', label)
}

export function joinItems(separator, field = 'label') {
  return join(getItems(), field, separator)
}
