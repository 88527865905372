import { diamondAttributes } from '@/constants/utilConstants.ts' // Import diamondAttributes data from categories.js
import _attributes from '@/mockedData/attributes.json'

export function getAttributes(type) {
  // TODO: migrate from window?._77Settings?.Attributes
  return (
    _attributes
      .filter((x) => x.AttributeGroupId == (diamondAttributes[type] || type))
      .sort((a, b) => a.Position - b.Position) || []
  )
}

export function getAttributesByStoneType(stoneType, type, id) {
  let attributes = getAttributesByStoneTypeFromSettings().filter((x) => {
    return x.StoneType == stoneType && x.AttributeGroupId == (diamondAttributes[type] || type)
  })

  if (!attributes) return null

  if (!id) {
    return attributes.sort(function (a, b) {
      return a.Position - b.Position
    })
  } else {
    return attributes.find((c) => c.Id == id) || {}
  }
}

export function getAttributeId(type, shortName) {
  return (getAttributes(type).find((a) => a.ShortName.toLowerCase() == shortName) || {}).Id
}

export function getAttributeName(type, id) {
  return (getAttributes(type).find((a) => a.Id == id) || {}).Name
}

export function getAttributeShortName(type, id) {
  return (getAttributes(type).find((a) => a.Id == id) || {}).ShortName
}

export function getAttributesByCountry(type) {
  return getAttributesByCountry()
    .filter(function (x) {
      return x.AttributeGroupId == (diamondAttributes[type] || type)
    })
    .sort(function (a, b) {
      return a.Position - b.Position
    })
}
