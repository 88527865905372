// TODO: handle code with jQuery
// function openTalkToExperts(diamond) {
//   var message = util.getPopupMessage(diamond)
//   showFooterForm('#Link123')
//   $('#TxtEmailusAdditionalinfo').html(message)
// }
//
// function openChat() {
//   $('.mainLiveChatBtn').first().trigger('click')
// }
import { WHATSAPP_LINK } from '~/constants/social'

//
export function openTalkToExperts(diamond) {
  console.log('openTalkToExperts')
}

export const openChat2 = () => {
  if (typeof window.Kustomer?.open === 'function') {
    window.Kustomer.open()
  }
}

export function openwhatsapp() {
  window.open(WHATSAPP_LINK, '_blank')
}

export function openCall() {
  window.showFooterForm('#Link121')
}

export function checkIfChatExists() {
  return !!document?.getElementById('liveHelp')
}
