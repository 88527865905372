import { SHOP_ROUTES_NAMES } from '~/constants'
import { DIAMOND_FILTER_QUERY_PARAMS, ITEM_DETAIL_FILTER_QUERY_PARAMS } from '~/constants/shop'
const { routeName } = useShopRouteName()

const keysToRemove = [
  'stoneType',
  'diamondType',
  'diamond',
  'shape',
  'shapes',
  'category',
  'subcategory',
  'subcategories',
  'item',
  'metal',
  'metals',
  'sort',
  'step',
  'isExpressShop',
  'selectedDiamond',
  'selectedDiamonds',
  'start-diamond',
  'undefined',
  'gemType',
  'fancyColorId',
  'stoneType,diamondType,gemType,fancyColorId',
  'stockNumber',
  'engraving',
  'engraving_font',
  'fancycolor',
  'stone',
  'gem',
  'gemshape',
  'gemshapes',
]

// Filters out unnecessary keys from the query object based on the current category and step.
const prepareShopQuery = (query: object, shopQuery: object) => {
  keysToRemove.forEach((key) => {
    if (query[key]) delete shopQuery[key]
  })
  // No diamond
  if (!routeName.value.includes('diamond')) {
    DIAMOND_FILTER_QUERY_PARAMS.forEach((key) => {
      if (query[key]) delete shopQuery[key]
    })
  } else {
    DIAMOND_FILTER_QUERY_PARAMS.forEach((key) => {
      if (Array.isArray(query[key]) && !query[key].length) delete shopQuery[key]
    })
  }

  if (!(query.step === SHOP_ROUTES_NAMES.ITEM || query.step === SHOP_ROUTES_NAMES.ITEM_FINISH)) {
    ITEM_DETAIL_FILTER_QUERY_PARAMS.forEach((key) => {
      if (query[key]) delete shopQuery[key]
    })
  }
}

// Stores the filtered query in session storage under the key '77dShopQuery'.
const store77dShopQuery = (query: object) => {
  const shopQuery = { ...query }

  prepareShopQuery(query, shopQuery)

  try {
    sessionStorage['77dShopQuery'] = JSON.stringify(shopQuery)
  } catch (e) {
    console.error('Failed to store shop query:', e)
  }

  return shopQuery
}

// Constructs a route string with query parameters appended.
const getRouteWithQuery = (path: string, query: string) => {
  return path + (query ? '?' + query : '')
}

export function useShopRoute() {
  const router = useRouter()
  const { translateShopAspRouteByQueryNew } = useAspRoutes()

  const updateShopRoute = (to: any) => {
    console.log('to', to)
    store77dShopQuery(to.query)
    const friendlyRoute = translateShopAspRouteByQueryNew(to)
    console.log('friendlyRoute', friendlyRoute)

    return router.push(friendlyRoute)
  }

  const getShopRouteByLegacyName = (to: any) => {
    const path = to.path || window.location.pathname
    const currentBrowserQuery = new URLSearchParams(window.location.search)
    const browserQuery = new URLSearchParams(window.location.search)
    Object.entries(to.query || {}).forEach(([key, value]) => {
      value && browserQuery.set(key, value)
    })

    // Remove empty query parameters
    Array.from(browserQuery.entries()).forEach(([key, value]) => {
      if (!value || value === 'undefined') {
        browserQuery.delete(key)
      }
    })

    if (to.name == SHOP_ROUTES_NAMES.ITEM) {
      browserQuery.set('step', SHOP_ROUTES_NAMES.ITEM)
      return getRouteWithQuery(path, browserQuery.toString())
    }
    if (to.name == SHOP_ROUTES_NAMES.ITEM_DIAMOND) {
      browserQuery.set('step', SHOP_ROUTES_NAMES.ITEM_DIAMOND)
      return getRouteWithQuery(path, browserQuery.toString())
    }
    if (to.name == SHOP_ROUTES_NAMES.ITEM_DIAMOND_DETAILS) {
      browserQuery.set('step', SHOP_ROUTES_NAMES.ITEM_DIAMOND_DETAILS)
      return getRouteWithQuery(path, browserQuery.toString())
    }
    if (to.name == SHOP_ROUTES_NAMES.ITEM_FINISH) {
      if (to.query?.diamond) browserQuery.set('diamond', to.query.diamond)
      browserQuery.set('step', SHOP_ROUTES_NAMES.ITEM_FINISH)

      return getRouteWithQuery(path, browserQuery.toString())
    }
    if (to.name == SHOP_ROUTES_NAMES.DIAMOND_DETAILS) {
      browserQuery.set('step', SHOP_ROUTES_NAMES.DIAMOND_DETAILS)
      browserQuery.set('diamond', to.query.diamond)
      return getRouteWithQuery(path, browserQuery.toString())
    }
    if (to.name == SHOP_ROUTES_NAMES.CATEGORY) {
      if (currentBrowserQuery.get('step') !== SHOP_ROUTES_NAMES.ITEM_FINISH) {
        browserQuery.delete('item')
        browserQuery.delete('diamond')
      }
      browserQuery.delete('step')
      return getRouteWithQuery(path, browserQuery.toString())
    }
    if (to.name == SHOP_ROUTES_NAMES.DIAMOND) {
      browserQuery.delete('diamond')
      browserQuery.delete('step')
      return getRouteWithQuery(path, browserQuery.toString())
    }

    return to
  }

  const shopRouter = {
    push: (to: string | object) => {
      console.log('shop router push', to)
      return updateShopRoute(to)
    },
    replace: (to: string | object) => {
      return updateShopRoute(to)
    },
  }

  return {
    shopRouter,
    getShopRouteByLegacyName,
  }
}
