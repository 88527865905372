import { findBy, join } from './general.js'
import { getAttributes } from '~/utils/shop/attributes.js'
import { getGemShapes } from './gems.js'
import { gemShapes, shapes } from '~/constants/utilConstants.ts'

export function getShapeById(id) {
  return findBy(shapes, 'id', id)
}

export function getShapeByValue(value) {
  return findBy(shapes, 'value', value)
}

export function getShape(value) {
  if (isNaN(value)) return getShapeByValue(value) || findBy(shapes, 'route', value) || {}
  else return getShapeById(value) || {}
}

export function getShapeIdByValue(value) {
  if (isNaN(value)) return (getShapeByValue(value) || findBy(shapes, 'route', value) || {}).id
  else return value
}

export function getShapeRoute(value) {
  return getShape(value).route
}

export function joinShapes(separator, field = 'value') {
  return join(shapes, field, separator, 'route')
}

export function getGemShapeGroup(shape) {
  shape = parseInt(shape)
  return getGemShapes().filter((s) => s.group.includes(shape))
}

export function getGemShapeById(id) {
  return gemShapes[id]
}

export function joinDiamonds(separator, field = 'value') {
  return getAttributes('shape')
    .map((shape) => shape.ShortName)
    .join(separator)
}

export const getShapeBasedOnGemstoneByQuery = (query, shapeId) => {
  return !(query.stoneType == 2) ? getShapeById(shapeId) : getGemShapeById(shapeId)
}
