
// @ts-nocheck


export const localeCodes =  [
  "ar",
  "bg",
  "cs",
  "dk",
  "nl",
  "en",
  "fi",
  "fr",
  "de",
  "it",
  "cn",
  "no",
  "pl",
  "pt",
  "ro",
  "ru",
  "es",
  "se",
  "hu",
  "page_id"
]

export const localeLoaders = {
  "ar": [],
  "bg": [],
  "cs": [],
  "dk": [],
  "nl": [],
  "en": [],
  "fi": [],
  "fr": [],
  "de": [],
  "it": [],
  "cn": [],
  "no": [],
  "pl": [],
  "pt": [],
  "ro": [],
  "ru": [],
  "es": [],
  "se": [],
  "hu": [],
  "page_id": []
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "i18n_config_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "id": 17,
      "code": "ar",
      "iso": "ar"
    },
    {
      "id": 26,
      "code": "bg",
      "iso": "bg-bg"
    },
    {
      "id": 22,
      "code": "cs",
      "iso": "cs-cz"
    },
    {
      "id": 14,
      "code": "dk",
      "iso": "da-dk"
    },
    {
      "id": 18,
      "code": "nl",
      "iso": "nl-nl"
    },
    {
      "id": 1,
      "code": "en",
      "iso": "en-gb"
    },
    {
      "id": 24,
      "code": "fi",
      "iso": "fi-fi"
    },
    {
      "id": 6,
      "code": "fr",
      "iso": "fr-fr"
    },
    {
      "id": 2,
      "code": "de",
      "iso": "de-de"
    },
    {
      "id": 4,
      "code": "it",
      "iso": "it-it"
    },
    {
      "id": 7,
      "code": "cn",
      "iso": "zh-cn"
    },
    {
      "id": 23,
      "code": "no",
      "iso": "nb-no"
    },
    {
      "id": 20,
      "code": "pl",
      "iso": "pl-pl"
    },
    {
      "id": 21,
      "code": "pt",
      "iso": "pt-pt"
    },
    {
      "id": 25,
      "code": "ro",
      "iso": "ro-ro"
    },
    {
      "id": 11,
      "code": "ru",
      "iso": "ru-ru"
    },
    {
      "id": 8,
      "code": "es",
      "iso": "es-es"
    },
    {
      "id": 13,
      "code": "se",
      "iso": "sv-se"
    },
    {
      "id": 27,
      "code": "hu",
      "iso": "hu-hu"
    },
    {
      "code": "page_id"
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const normalizedLocales = [
  {
    "id": 17,
    "code": "ar",
    "iso": "ar",
    "files": []
  },
  {
    "id": 26,
    "code": "bg",
    "iso": "bg-bg",
    "files": []
  },
  {
    "id": 22,
    "code": "cs",
    "iso": "cs-cz",
    "files": []
  },
  {
    "id": 14,
    "code": "dk",
    "iso": "da-dk",
    "files": []
  },
  {
    "id": 18,
    "code": "nl",
    "iso": "nl-nl",
    "files": []
  },
  {
    "id": 1,
    "code": "en",
    "iso": "en-gb",
    "files": []
  },
  {
    "id": 24,
    "code": "fi",
    "iso": "fi-fi",
    "files": []
  },
  {
    "id": 6,
    "code": "fr",
    "iso": "fr-fr",
    "files": []
  },
  {
    "id": 2,
    "code": "de",
    "iso": "de-de",
    "files": []
  },
  {
    "id": 4,
    "code": "it",
    "iso": "it-it",
    "files": []
  },
  {
    "id": 7,
    "code": "cn",
    "iso": "zh-cn",
    "files": []
  },
  {
    "id": 23,
    "code": "no",
    "iso": "nb-no",
    "files": []
  },
  {
    "id": 20,
    "code": "pl",
    "iso": "pl-pl",
    "files": []
  },
  {
    "id": 21,
    "code": "pt",
    "iso": "pt-pt",
    "files": []
  },
  {
    "id": 25,
    "code": "ro",
    "iso": "ro-ro",
    "files": []
  },
  {
    "id": 11,
    "code": "ru",
    "iso": "ru-ru",
    "files": []
  },
  {
    "id": 8,
    "code": "es",
    "iso": "es-es",
    "files": []
  },
  {
    "id": 13,
    "code": "se",
    "iso": "sv-se",
    "files": []
  },
  {
    "id": 27,
    "code": "hu",
    "iso": "hu-hu",
    "files": []
  },
  {
    "code": "page_id",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const STRATEGIES = {
  "PREFIX": "prefix",
  "PREFIX_EXCEPT_DEFAULT": "prefix_except_default",
  "PREFIX_AND_DEFAULT": "prefix_and_default",
  "NO_PREFIX": "no_prefix"
}
export const DEFAULT_LOCALE = ""
export const DEFAULT_STRATEGY = "prefix_except_default"
export const DEFAULT_TRAILING_SLASH = false
export const DEFAULT_ROUTES_NAME_SEPARATOR = "___"
export const DEFAULT_LOCALE_ROUTE_NAME_SUFFIX = "default"
export const DEFAULT_DETECTION_DIRECTION = "ltr"
export const DEFAULT_BASE_URL = ""
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"


