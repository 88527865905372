<template>
  <NuxtLayout v-if="!pending">
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { useShoppingBagStore } from '~/store/shoppingBagStore'
import { useNewSettingsStore } from '~/store/newSettingsStore'
import { useBreadcrumbsStore } from '~/store/breadcrumbsStore'
import { useRouteStore } from '~/store/routesStore'
const { getUserPreferences, getUserDiamondPreferences } = useUser()
const { getBag } = useShoppingBagStore()
const { setCountries, setCurrencies, setCurrentCurrency, setCurrentCountry, setLanguages, setCurrentLanguage, setPhoneNumbers } =
  useNewSettingsStore()
const { fetchBreadcrumbs } = useBreadcrumbsStore()
const { initEnStaticRoutes } = useRouteStore()

const initData = async () => {
  await Promise.all([getUserPreferences(), setCountries(), setCurrencies()])
  setCurrentCurrency()
  setCurrentCountry()
  await setLanguages()
  setCurrentLanguage()
  return true
}

const initLazyData = async () => {
  await Promise.all([fetchBreadcrumbs(), getUserDiamondPreferences(), getBag(), setPhoneNumbers()])
}
const { pending } = await useAsyncData('user-preferences', initData)

onMounted(async () => {
  await initLazyData()
  nextTick(() => {
    initShoppingBagLocalStorageListener()
    initEnStaticRoutes()
  })
})
</script>
<style></style>
