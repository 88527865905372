import subcategoriesData from '@/data/subcategories.json'
import { findBy, join } from '~/utils/shop/general.js'

export function getSubCategories() {
  return subcategoriesData.map((c) => ({ ...c, route: c.label }))
}

const subCategories = getSubCategories()

export function getSubCategoryById(id) {
  return findBy(subCategories, 'id', id)
}

export function getSubCategoryByValue(value) {
  return findBy(subCategories, 'value', value)
}

export function getSubCategory(value) {
  if (isNaN(value)) return getSubCategoryByValue(value) || findBy(subCategories, 'route', value)
  else return getSubCategoryById(value)
}

export function getSubCategoryLabel(value, customField) {
  const subcat = getSubCategory(value)
  return subcat?.[customField] || subcat?.label || ''
}

export function getSubCategoryIdByValue(value) {
  if (isNaN(value)) return (getSubCategory(value) || {}).id
  else return value
}

export function getSubCategoryByLabel(label) {
  return findBy(subCategories, 'label', label)
}

export function joinSubCategories(separator, field = 'label') {
  return join(subCategories, field, separator, 'route')
}
