import { METALS } from './metals'
import { SHAPES, GEM_SHAPES } from './shapes'
import _77T from '@/utils/77translation'

const _shapes = [
  {
    id: SHAPES.ROUND,
    value: 'round',
    label: 'round',
    img: 'round',
    description: 'Most popular diamond shape for the most brilliance',
  },
  {
    id: SHAPES.PRINCESS,
    value: 'princess',
    label: 'princess',
    img: 'princess',
    description: 'Most popular diamond shape for the most brilliance',
  },
  {
    id: SHAPES.EMERALD,
    value: 'emerald',
    label: 'emerald',
    img: 'emerald',
    description: 'Most popular diamond shape for the most brilliance',
  },
  {
    id: SHAPES.ASSCHER,
    value: 'asscher',
    label: 'asscher',
    img: 'asscher',
    description: 'Most popular diamond shape for the most brilliance',
  },
  {
    id: SHAPES.PEAR,
    value: 'pear',
    label: 'pear',
    img: 'pear',
    description: 'Most popular diamond shape for the most brilliance',
  },
  {
    id: SHAPES.RADIANT,
    value: 'radiant',
    label: 'radiant',
    img: 'radiant',
    description: 'Most popular diamond shape for the most brilliance',
  },
  {
    id: SHAPES.OVAL,
    value: 'oval',
    label: 'oval',
    img: 'oval',
    description: 'Most popular diamond shape for the most brilliance',
  },
  {
    id: SHAPES.CUSHION,
    value: 'cushion',
    label: 'cushion',
    img: 'cushion',
    description: 'Most popular diamond shape for the most brilliance',
  },
  {
    id: SHAPES.HEART,
    value: 'heart',
    label: 'heart',
    img: 'heart',
    description: 'Most popular diamond shape for the most brilliance',
  },
  {
    id: SHAPES.MARQUISE,
    value: 'marquise',
    label: 'marquise',
    img: 'marquise',
    description: 'Most popular diamond shape for the most brilliance',
  },
  {
    id: SHAPES.OLD_CUTS,
    value: 'Old Cuts',
    label: 'old-cuts',
    img: 'oldcut',
    description: '',
  },
  {
    id: SHAPES.KITES_SHIELDS,
    value: 'Kites & Shields',
    label: 'kites-shields',
    img: 'kite',
    description: '',
  },
  {
    id: SHAPES.TRIANGULARS,
    value: 'Triangulars',
    label: 'triangulars',
    img: 'triangular',
    description: '',
  },
  {
    id: SHAPES.OCTAGON,
    value: 'Octagon',
    label: 'octagon',
    img: 'octagon',
    description: '',
  },
]

export const shapes = _shapes.map((shape) => ({
  ...shape,
  route: shape.label,
}))

export const gemShapes = {
  [GEM_SHAPES.ROUND]: {
    id: GEM_SHAPES.ROUND,
    value: 'round',
    label: 'round',
    img: 'round',
    description: 'Most popular diamond shape for the most brilliance',
  },
  [GEM_SHAPES.OVAL]: {
    id: GEM_SHAPES.OVAL,
    value: 'oval',
    label: 'oval',
    img: 'oval',
    description: 'Most popular diamond shape for the most brilliance',
  },
  [GEM_SHAPES.PEAR]: {
    id: GEM_SHAPES.PEAR,
    value: 'pear',
    label: 'pear',
    img: 'pear',
    description: 'Most popular diamond shape for the most brilliance',
  },
  [GEM_SHAPES.CUSHION]: {
    id: GEM_SHAPES.CUSHION,
    value: 'cushion',
    label: 'cushion',
    img: 'cushion',
    description: 'Most popular diamond shape for the most brilliance',
  },
  [GEM_SHAPES.HEART]: {
    id: GEM_SHAPES.HEART,
    value: 'heart',
    label: 'heart',
    img: 'heart',
    description: 'Most popular diamond shape for the most brilliance',
  },
  [GEM_SHAPES.RECTANGLE]: {
    id: GEM_SHAPES.RECTANGLE,
    value: 'rectangle',
    label: 'rectangle',
    img: 'rectangle',
    description: 'Most popular diamond shape for the most brilliance',
  },
  [GEM_SHAPES.MARQUISE]: {
    id: GEM_SHAPES.MARQUISE,
    value: 'marquise',
    label: 'marquise',
    img: 'marquise',
    description: 'Most popular diamond shape for the most brilliance',
  },
  [GEM_SHAPES.SQUARE]: {
    id: GEM_SHAPES.SQUARE,
    value: 'square',
    label: 'square',
    img: 'square',
    description: 'Most popular diamond shape for the most brilliance',
  },
  [GEM_SHAPES.OTHER]: {
    id: GEM_SHAPES.OTHER,
    value: 'other',
    label: 'other',
    img: 'other',
    description: 'Most popular diamond shape for the most brilliance',
  },
}
export const salutations = [
  { value: 1, label: 'Mr.', askForGender: false, languageId: 1 },
  { value: 2, label: 'Ms.', askForGender: false, languageId: 1 },
  { value: 3, label: 'Mrs.', askForGender: false, languageId: 1 },
  { value: 4, label: 'Miss.', askForGender: false, languageId: 1 },
  { value: 5, label: 'Dr.', askForGender: true, languageId: 1 },
  { value: 6, label: 'Lady', askForGender: false, languageId: 1 },
  { value: 7, label: 'Madam', askForGender: false, languageId: 1 },
  { value: 8, label: 'Mr. and Mrs.', askForGender: false, languageId: 1 },
  { value: 9, label: 'Prof.', askForGender: true, languageId: 1 },
  { value: 10, label: 'Sir.', askForGender: false, languageId: 1 },
  { value: 11, label: 'Herr', askForGender: false, languageId: 2 },
  { value: 12, label: 'Frau', askForGender: false, languageId: 2 },
  { value: 13, label: 'Dr.', askForGender: true, languageId: 2 },
  { value: 14, label: 'Dr.med.', askForGender: true, languageId: 2 },
  { value: 15, label: 'Prof.', askForGender: true, languageId: 2 },
  { value: 16, label: 'Prof. Dr.', askForGender: true, languageId: 2 },
  { value: 125, label: '博士', askForGender: true, languageId: 7 },
  { value: 126, label: '女士', askForGender: false, languageId: 7 },
  { value: 127, label: '夫人', askForGender: false, languageId: 7 },
  { value: 128, label: '小姐', askForGender: false, languageId: 7 },
  { value: 129, label: '先生', askForGender: false, languageId: 7 },
  { value: 130, label: '先生和太太', askForGender: false, languageId: 7 },
  { value: 131, label: '太太', askForGender: false, languageId: 7 },
  { value: 132, label: '女士', askForGender: false, languageId: 7 },
  { value: 105, label: 'Dr.', askForGender: true, languageId: 8 },
  { value: 106, label: 'Señorita', askForGender: false, languageId: 8 },
  { value: 107, label: 'Señora', askForGender: false, languageId: 8 },
  { value: 108, label: 'Srta.', askForGender: false, languageId: 8 },
  { value: 109, label: 'Sr.', askForGender: false, languageId: 8 },
  { value: 110, label: 'Sr. y Sra.', askForGender: false, languageId: 8 },
  { value: 111, label: 'Sra.', askForGender: false, languageId: 8 },
  { value: 32, label: 'M.', askForGender: false, languageId: 6 },
  { value: 44, label: 'Dr.', askForGender: true, languageId: 6 },
  { value: 34, label: 'Mme', askForGender: false, languageId: 6 },
  { value: 35, label: 'Melle', askForGender: false, languageId: 6 },
  { value: 45, label: 'Mr.', askForGender: false, languageId: 12 },
  { value: 46, label: 'Ms.', askForGender: false, languageId: 12 },
  { value: 47, label: 'Mrs.', askForGender: false, languageId: 12 },
  { value: 48, label: 'Miss.', askForGender: false, languageId: 12 },
  { value: 49, label: 'Dr.', askForGender: true, languageId: 12 },
  { value: 50, label: 'Lady', askForGender: false, languageId: 12 },
  { value: 51, label: 'Madam', askForGender: false, languageId: 12 },
  { value: 52, label: 'Mr. and Mrs.', askForGender: false, languageId: 12 },
  { value: 53, label: 'Prof.', askForGender: true, languageId: 12 },
  { value: 54, label: 'Sir.', askForGender: false, languageId: 12 },
  { value: 55, label: 'Herr.', askForGender: false, languageId: 13 },
  { value: 56, label: 'Fröken.', askForGender: false, languageId: 13 },
  { value: 57, label: 'Fru.', askForGender: false, languageId: 13 },
  { value: 58, label: 'Fröken.', askForGender: false, languageId: 13 },
  { value: 59, label: 'Dr.', askForGender: true, languageId: 13 },
  { value: 60, label: 'Lady', askForGender: false, languageId: 13 },
  { value: 61, label: 'Fröken', askForGender: false, languageId: 13 },
  { value: 62, label: 'Mr. och Mrs.', askForGender: false, languageId: 13 },
  { value: 63, label: 'Prof.', askForGender: true, languageId: 13 },
  { value: 64, label: 'Herr.', askForGender: false, languageId: 13 },
  { value: 65, label: 'Hr.', askForGender: false, languageId: 14 },
  { value: 66, label: 'Fru.', askForGender: false, languageId: 14 },
  { value: 67, label: 'Frk.', askForGender: false, languageId: 14 },
  { value: 68, label: 'Frøken', askForGender: false, languageId: 14 },
  { value: 69, label: 'Dr.', askForGender: true, languageId: 14 },
  { value: 70, label: 'Lady', askForGender: false, languageId: 14 },
  { value: 71, label: 'Madame', askForGender: false, languageId: 14 },
  { value: 72, label: 'Hr. og Fru.', askForGender: false, languageId: 14 },
  { value: 73, label: 'Prof.', askForGender: true, languageId: 14 },
  { value: 74, label: 'Hr.', askForGender: false, languageId: 14 },
  { value: 85, label: 'Доктор', askForGender: true, languageId: 11 },
  { value: 86, label: 'Госпожа', askForGender: false, languageId: 11 },
  { value: 87, label: 'Госпожа', askForGender: false, languageId: 11 },
  { value: 88, label: 'Госпожа', askForGender: false, languageId: 11 },
  { value: 89, label: 'Господин', askForGender: false, languageId: 11 },
  {
    value: 90,
    label: 'Дамы и господа',
    askForGender: false,
    languageId: 11,
  },
  { value: 91, label: 'Госпожа', askForGender: false, languageId: 11 },
  { value: 92, label: 'Госпожа', askForGender: false, languageId: 11 },
  { value: 93, label: 'Профессор', askForGender: true, languageId: 11 },
  { value: 94, label: 'Господин', askForGender: false, languageId: 11 },
  { value: 112, label: 'Srta.', askForGender: false, languageId: 8 },
  { value: 113, label: 'Prof.', askForGender: true, languageId: 8 },
  { value: 114, label: 'Señor.', askForGender: false, languageId: 8 },
  { value: 133, label: '教授', askForGender: true, languageId: 7 },
  { value: 134, label: '先生', askForGender: false, languageId: 7 },
  { value: 145, label: 'Dr.', askForGender: true, languageId: 4 },
  { value: 146, label: 'Signora', askForGender: false, languageId: 4 },
  { value: 147, label: 'Signora', askForGender: false, languageId: 4 },
  { value: 148, label: 'Sig.ina', askForGender: false, languageId: 4 },
  { value: 149, label: 'Sig.', askForGender: false, languageId: 4 },
  {
    value: 150,
    label: 'Sig.ra e Sig.',
    askForGender: false,
    languageId: 4,
  },
  { value: 151, label: 'Sig.ra', askForGender: false, languageId: 4 },
  { value: 152, label: 'Sig.ra', askForGender: false, languageId: 4 },
  { value: 153, label: 'Prof.', askForGender: true, languageId: 4 },
  { value: 154, label: 'Sig.', askForGender: false, languageId: 4 },
  { value: 155, label: 'الدكتور', askForGender: true, languageId: 17 },
  { value: 156, label: 'السيدة', askForGender: false, languageId: 17 },
  { value: 157, label: 'السيدة', askForGender: false, languageId: 17 },
  { value: 158, label: 'الآنسة', askForGender: false, languageId: 17 },
  { value: 159, label: 'السيد', askForGender: false, languageId: 17 },
  {
    value: 160,
    label: 'السيد والسيدة',
    askForGender: false,
    languageId: 17,
  },
  { value: 161, label: 'السيدة', askForGender: false, languageId: 17 },
  { value: 162, label: 'السيدة', askForGender: false, languageId: 17 },
  { value: 163, label: 'الأستاذ', askForGender: true, languageId: 17 },
  { value: 164, label: 'السيد', askForGender: false, languageId: 17 },
  { value: 165, label: 'Dr.', askForGender: true, languageId: 18 },
  { value: 166, label: 'Juffrouw', askForGender: false, languageId: 18 },
  { value: 167, label: 'Mevrouw', askForGender: false, languageId: 18 },
  { value: 168, label: 'Mej.', askForGender: false, languageId: 18 },
  { value: 169, label: 'M.', askForGender: false, languageId: 18 },
  { value: 170, label: 'M. en Mevr.', askForGender: false, languageId: 18 },
  { value: 171, label: 'Mevr.', askForGender: false, languageId: 18 },
  { value: 172, label: 'Mevr.', askForGender: false, languageId: 18 },
  { value: 173, label: 'Prof.', askForGender: true, languageId: 18 },
  { value: 174, label: 'Heer', askForGender: false, languageId: 18 },
]

export const _weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

export const _months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
]

export const iphonePPCM = {
  'iphone-xs-max': 60,
  'iphone-xs': 60,
  'iphone-xr': 64,
  'iphone-x': 60,
  'iphone-6+,': 53,
  'iphone-6s+': 53,
  'iphone-7+': 53,
  'iphone-8+': 53,
  'iphone-7,': 64,
  'iphone-8': 64,
  'iphone-6,': 64,
  'iphone-6s': 64,
  'iphone-5': 64,
  'iphone-4': 64,
  'iphone-4s': 64,
  'iphone-3': 64,
}

export const diamondAttributes = {
  shape: 1,
  color: 2,
  clarity: 3,
  certificate: 4,
  cut: 5,
  fluorescence: 6,
  polish: 7,
  symmetry: 8,
  culet: 9,
  girdle: 10,
  intensity: 11,
  depth: 12,
  width: 13,
  ringSize: 14,
  fancyColor: 25,
  sources: 26,
  gemType: 41,
  gemTreatment: 40,
  gemClarity: 42,
  gemIntensity: 39,
  gemEyeClean: 32,
  countryOfOrigin: 43,
  gemClarityTreatment: 44,
  measurements: 35,
}

const _metals = [
  {
    id: METALS.YELLOW_GOLD,
    value: 'yellow-gold',
    label: 'yellow-gold-18k',
  },
  {
    id: METALS.WHITE_GOLD,
    value: 'white-gold',
    label: 'white-gold-18k',
  },
  {
    id: METALS.ROSE_GOLD,
    value: 'rose-gold',
    label: 'rose-gold-18k',
  },
  {
    id: METALS.PLATINUM,
    value: 'platinum',
    label: 'platinum-950',
  },
  {
    id: METALS.PALLADIUM,
    value: 'palladium',
    label: 'palladium-950',
  },
  {
    id: METALS.PALLADIUM_500,
    value: 'palladium',
    label: 'palladium-500',
  },
]

export const metals = _metals.map((metal) => ({
  ...metal,
  route: metal.label,
}))
