import revive_payload_client_YVMEtB6qbn from "/src/node_modules/.pnpm/nuxt@3.9.1_@parcel+watcher@2.3.0_@types+node@18.19.7_encoding@0.1.13_eslint@8.56.0_optionator_t7pwgj677tsyg74zmxcwjpvgpa/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_TKcBprMb9w from "/src/node_modules/.pnpm/nuxt@3.9.1_@parcel+watcher@2.3.0_@types+node@18.19.7_encoding@0.1.13_eslint@8.56.0_optionator_t7pwgj677tsyg74zmxcwjpvgpa/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_JDYghQWSAX from "/src/node_modules/.pnpm/nuxt@3.9.1_@parcel+watcher@2.3.0_@types+node@18.19.7_encoding@0.1.13_eslint@8.56.0_optionator_t7pwgj677tsyg74zmxcwjpvgpa/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Bjn1Dvh7WT from "/src/node_modules/.pnpm/nuxt@3.9.1_@parcel+watcher@2.3.0_@types+node@18.19.7_encoding@0.1.13_eslint@8.56.0_optionator_t7pwgj677tsyg74zmxcwjpvgpa/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_SxzD6RpSoW from "/src/node_modules/.pnpm/nuxt@3.9.1_@parcel+watcher@2.3.0_@types+node@18.19.7_encoding@0.1.13_eslint@8.56.0_optionator_t7pwgj677tsyg74zmxcwjpvgpa/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_OfC8PmuCIB from "/src/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.9.5_typescript@5.3.3_vue@3.4.13_typescript@5.3.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/src/.nuxt/components.plugin.mjs";
import prefetch_client_Xv7p6qBDqM from "/src/node_modules/.pnpm/nuxt@3.9.1_@parcel+watcher@2.3.0_@types+node@18.19.7_encoding@0.1.13_eslint@8.56.0_optionator_t7pwgj677tsyg74zmxcwjpvgpa/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_pPPzPqHLO8 from "/src/node_modules/.pnpm/@samk-dev+nuxt-vcalendar@1.0.4_rollup@4.9.5_vue@3.4.13_typescript@5.3.3_/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import i18n_J80ImWmo0R from "/src/node_modules/.pnpm/@nuxtjs+i18n@8.1.1_rollup@4.9.5_vue@3.4.13_typescript@5.3.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_WO2HkULHGU from "/src/node_modules/.pnpm/nuxt-viewport@2.1.0_rollup@4.9.5_vue@3.4.13_typescript@5.3.3_/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import chunk_reload_client_0aJPgEnAC8 from "/src/node_modules/.pnpm/nuxt@3.9.1_@parcel+watcher@2.3.0_@types+node@18.19.7_encoding@0.1.13_eslint@8.56.0_optionator_t7pwgj677tsyg74zmxcwjpvgpa/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import i18n_loader_KkCDvE77RQ from "/src/plugins/i18n-loader.js";
import floating_vue_BiOD74u9sH from "/src/plugins/floating-vue.ts";
import kustomer_client_g2QShyDNSq from "/src/plugins/kustomer.client.js";
import ometria_client_pYMHeAHj8Q from "/src/plugins/ometria.client.js";
import redirect_CeAqr2JrWj from "/src/plugins/redirect.js";
import swiper_eteSCvuYkd from "/src/plugins/swiper.ts";
import vue_the_mask_3Ai82AaTlI from "/src/plugins/vue-the-mask.js";
export default [
  revive_payload_client_YVMEtB6qbn,
  unhead_TKcBprMb9w,
  router_JDYghQWSAX,
  payload_client_Bjn1Dvh7WT,
  check_outdated_build_client_SxzD6RpSoW,
  plugin_vue3_OfC8PmuCIB,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Xv7p6qBDqM,
  plugin_client_pPPzPqHLO8,
  i18n_J80ImWmo0R,
  plugin_client_WO2HkULHGU,
  chunk_reload_client_0aJPgEnAC8,
  i18n_loader_KkCDvE77RQ,
  floating_vue_BiOD74u9sH,
  kustomer_client_g2QShyDNSq,
  ometria_client_pYMHeAHj8Q,
  redirect_CeAqr2JrWj,
  swiper_eteSCvuYkd,
  vue_the_mask_3Ai82AaTlI
]