export const DEFAULT_STONE_TYPE = 1
export const DEFAULT_DIAMOND_TYPE = -1

export const DIAMOND_FILTER_QUERY_PARAMS = [
  'clarity',
  'intensity',
  'cut',
  'polish',
  'fluorescence',
  'gemIntensity',
  'gemClarity',
]
export const ITEM_DETAIL_FILTER_QUERY_PARAMS = [
  'size',
  'width',
  'depth',
  'sizeNameAttributeId',
  'component',
  'componentSet ',
  'variation',
]

export const DIAMOND_TAB_IDENTIFIER = [
  {
    query: [
      ['stoneType', 1],
      ['diamondType', -1],
    ],
    name: 'natural',
  },
  {
    query: [
      ['stoneType', 1],
      ['diamondType', -2],
    ],
    name: 'natural',
  },
  {
    query: [
      ['stoneType', 3],
      ['diamondType', -1],
    ],
    name: 'lab-grown',
  },
  {
    query: [
      ['stoneType', 3],
      ['diamondType', -2],
    ],
    name: 'lab-grown',
  },
  {
    query: [
      ['stoneType', null],
      ['diamondType', -3],
    ],
    name: 'coloured',
  },
  {
    query: [
      ['stoneType', 2],
      ['diamondType', -4],
    ],
    name: 'gemstones',
  },
]
